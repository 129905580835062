import React, { useState, useEffect, useContext } from 'react';
import { HeaderContext } from '../../../HeaderContext';
import { DataSpaceViewContext } from '../context/DataSpaceViewContext';
import { UserProfileContext } from '../../../UserProfileContext';
import DataTable from './DataTable';
import { Alert } from '@material-ui/lab';
import LuxonAdapter from '@material-ui/lab/AdapterLuxon';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { DateTime } from 'luxon';
import DetailsPane from './DetailsPane';
import CSVExporter from './CSVExporter';
import {
	makeStyles,
	CircularProgress,
	Backdrop,
	Snackbar,
	Button,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	IconButton,
	InputAdornment,
	SvgIcon,
} from '@material-ui/core/';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SearchIcon from '@material-ui/icons/Search';
import { trackEvent } from '../../../utils/eventTracking';
import MyDataSpacesViewContext from '../context/MyDataSpacesViewContext';
import getLogo from '../../../utils/getLogo';
import {
	retrieveJoinedDataSpaceData,
	updateDataSpaceStateNew,
	generateJoinedDataSpace,
	getSpecifiedDataSpaceMetadata,
	getDataSpaces,
	getDataSpaceRows,
	updateDataSpaceData,
} from '../../../api_helper/api';
import './DataSpaceView.css';
import SnackbarAlertContext from '../../../context/SnackbarAlertContext';
import ShareDataSpaceDialog from '../ShareDataSpaceDialog';
import ErrorBoundary from '../../ui_elements/ErrorBoundary';
import { ReactComponent as AddFormulaIcon } from '../../../assets/icons/add-formula.svg';

const useStyles = makeStyles((theme) => ({
	rightside_component_group: {
		display: 'inline-flex',
		justifyContent: 'end',
		alignItems: 'center',
		margin: '0 0.25rem 0.5rem 0',
	},
	button: {
		height: 'fit-content',
		paddingTop: '0.4rem',
		paddingBottom: '0.4rem',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		transition: '0.3s',
		'&:hover': {
			backgroundColor: '#C4C4C4',
			filter: 'brightness(0.75)',
		},
		'& > span': {
			width: '100%',
			display: 'inline',
			color: '#000000',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	datasetFieldSelected: {
		backgroundColor: '#0DBC42',
		'&:hover': {
			backgroundColor: '#0DBC42',
			filter: 'brightness(0.75)',
		},
	},
	seeDetailsButton: {
		color: 'black',
	},
}));

const dateRegexes = [
	{
		// "game_time": "Wed, 02 Mar 2019 22:00:00 GMT" Leading 0 on single digit days.
		dateType: 'httpStandard',
		regex: new RegExp('[A-z]{3}, [0-9]{2} [A-z]{3} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} GMT'),
	},
	{
		// "age": "May 5, 1991" (No leading 0 on single digit day)
		dateType: 'mon_dd,_yyyy',
		regex: new RegExp(
			'((?:January|February|March|April|May|June|July|August|September|October|November|December) [0-9]{1,2}, [0-9]{4})',
		),
	},
	{
		// "date_injured": "2019-9-27" (No leading 0 on single digit month/day)
		dateType: 'yyyy-mm-dd',
		regex: new RegExp('^[0-9]{4}-([0-9]{2}|[0-9]{1})-([0-9]{2}|[0-9]{1})$'),
	},
	{
		//"timestamp": "1568948695"
		dateType: 'unixTimestamp',
		regex: new RegExp('^[0-9]{8}([0-9]{1,2})?$'),
	},
];

// Converts all date values to predefined uniform formats
const applyCustomDateFormats = (dataSpaceData) => {
	let { data } = dataSpaceData;
	const dateColumns = identifyDateColumnsAndType(dataSpaceData);
	dateColumns.forEach((dateCol) => {
		const { field, dateType } = dateCol;
		data.forEach((row) => {
			if (field in row) {
				row[field] = convertDateToCustomISOFormat(dateType, row[field]);
			}
		});
	});
	return data;
};

// Converts date or datetime to ISO value and adds suffix
// identifying Date type
const convertDateToCustomISOFormat = (dateType, actualDate) => {
	if ([null, undefined].includes(actualDate)) return actualDate;

	let luxonDate;
	let isoDate;
	switch (dateType) {
		case 'yyyy-mm-dd':
			luxonDate = DateTime.fromFormat(actualDate, 'yyyy-M-d');
			isoDate = luxonDate.toISO(luxonDate) + '_DATE';
			break;
		case 'httpStandard':
			luxonDate = DateTime.fromHTTP(actualDate);
			isoDate = luxonDate.toISO(luxonDate) + '_DATETIME';
			break;
		case 'mon_dd,_yyyy':
			luxonDate = DateTime.fromFormat(actualDate, 'LLLL d, yyyy');
			isoDate = luxonDate.toISO(luxonDate) + '_DATE';
			break;
		case 'unixTimestamp':
			luxonDate = DateTime.fromSeconds(parseInt(actualDate));
			isoDate = luxonDate.toISO(luxonDate) + '_DATETIME';
			break;
		default:
			throw new Error('Invalid date format specified: ' + dateType);
	}

	return isoDate;
};

// Identify columns containing Date values and their initial formats
const identifyDateColumnsAndType = (dataSpaceData) => {
	const { columns, data } = dataSpaceData;

	const fieldArray = columns.map((col) => col.field);
	const dateColumns = [];

	fieldArray.forEach((field) => {
		let dataType = '';
		let value;
		let i = 0;
		while (i < data.length && dataType === '') {
			if (typeof data[i][field] !== 'undefined') {
				dataType = typeof data[i][field];
				value = data[i][field];
			}
			i++;
		}

		i = 0;
		if (dataType === 'string') {
			// Check if it is a date and output console info to check method
			let isDateTypeFound = false;
			while (!isDateTypeFound && i < dateRegexes.length) {
				isDateTypeFound = dateRegexes[i].regex.test(value);
				if (isDateTypeFound) {
					dateColumns.push({
						field: field,
						dateType: dateRegexes[i].dateType,
					});
				}
				i++;
			}
		}
	});

	return dateColumns;
};

export default function DataSpaceViewNew(props) {
	const classes = useStyles();

	// To modify SportWise header
	const setHeaderContent = useContext(HeaderContext)[1];

	// To store and use view variables without prop drilling
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const setDataSpaceId = dataSpaceViewContext.dataSpaceId[1];

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const snackbarAlertContext = useContext(SnackbarAlertContext);

	// Loading Backdrop
	const [loadingOpen, setLoadingOpen] = useState(false);

	const [isSelectColumnsDialogVisible, setIsSelectColumnsDialogVisible] = useState(false);

	const [selectColumnsDialogSearchInput, setSelectColumnsDialogSearchInput] = useState('');

	// Details Pane
	const [detailsOpen, setDetailsOpen] = useState(false);
	const handleClickSeeDetails = () => setDetailsOpen(true);
	const onDetailsClose = () => setDetailsOpen(false);

	// Snackbar and alert
	const [snackOpen, setSnackOpen] = useState(false);
	const onSnackClose = () => setSnackOpen(false);
	const [snackMessage, setSnackMessage] = useState('');
	const [alertSeverity, setAlertSeverity] = useState('');
	const [snackAutoHideMs, setSnackAutoHideMs] = useState(5000);

	const user = useContext(UserProfileContext)[0];

	const displayAlert = (severity, message) => {
		setAlertSeverity(severity);
		setSnackMessage(message);
		setSnackOpen(true);
	};

	// Hold table data
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	// Hold name of LMAPI (if any). Used when refreshing user's DataSpace
	const [lmapiName, setLmapiName] = useState();

	const [selectedColumns, setSelectedColumns] = useState([]); // Used in hide/show column toggle menu

	// Calculated Column Panel
	const [calcColPanelOpen, setCalcColPanelOpen] = useState(false);
	const handleCloseCalcColPanel = () => setCalcColPanelOpen(false);

	const selectColumnsDialogSearchChangeHandler = (e) => {
		setSelectColumnsDialogSearchInput(e.target.value);
	};

	const columnClickHandler = (clickedColumn) => {
		const isColumnBeingAdded = !selectedColumns.some(
			(selectedColumn) => selectedColumn.field === (clickedColumn.name || clickedColumn.field),
		);

		let updatedColumns = isColumnBeingAdded
			? [...selectedColumns, clickedColumn]
			: [...selectedColumns].filter(
					(selectedColumn) => selectedColumn.field !== (clickedColumn.name || clickedColumn.field),
			  );

		// Get corresponding column values from columns
		updatedColumns = updatedColumns.map((updatedColumn) => {
			return columns.find((column) => column.field === (updatedColumn.field || updatedColumn.name));
		});

		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: updatedColumns });
	};

	const deselectAllColumnsClickHandler = () => {
		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: [columns[0]] });
	};

	const selectAllColumnsClickHandler = () => {
		let updatedColumns = [...new Set([...selectedColumns, ...columns])];

		// Get corresponding column values from columns
		updatedColumns = updatedColumns.map((updatedColumn) => {
			return columns.find((column) => column.field === (updatedColumn.field || updatedColumn.name));
		});

		// Pass updatedColumns to existing column select handler.
		onColumnToggle({ value: updatedColumns });
	};

	const selectColumnsDatasetColumns = (dataset) => {
		let columnButtons;
		if (dataset === 'Calculated') {
			columnButtons = columns?.reduce((accumulator, column) => {
				// If column doesn't match search filter, isn't a Calculated Column, or is excluded don't show column.
				if (
					(selectColumnsDialogSearchInput &&
						!(column.header || column.field).toLowerCase().includes(selectColumnsDialogSearchInput.toLowerCase())) ||
					column.dataSourceName !== 'Calculated' ||
					column.isExcluded
				) {
					return accumulator;
				}

				return [
					...accumulator,
					<Grid key={column.name} item xs={6} sm={4}>
						<Button
							className={
								classes.datasetField +
								(selectedColumns.some((selectedColumn) => selectedColumn.field === column.field)
									? ' ' + classes.datasetFieldSelected
									: '')
							}
							onClick={() => {
								columnClickHandler(column);
							}}
						>
							{column.header || column.field}
						</Button>
					</Grid>,
				];
			}, []);
		} else {
			columnButtons = dataset.fields.reduce((accumulator, column) => {
				// If column doesn't match search filter or is excluded don't show column.
				if (
					(selectColumnsDialogSearchInput &&
						!(column.displayName || column.name)
							.toLowerCase()
							.includes(selectColumnsDialogSearchInput.toLowerCase())) ||
					column.isExcluded
				) {
					return accumulator;
				}

				return [
					...accumulator,
					<Grid key={column.name} item xs={6} sm={4}>
						<Button
							className={
								classes.datasetField +
								(selectedColumns.some((selectedColumn) => selectedColumn.field === column.name)
									? ' ' + classes.datasetFieldSelected
									: '')
							}
							onClick={() => {
								columnClickHandler(column);
							}}
						>
							{column.displayName || column.name}
						</Button>
					</Grid>,
				];
			}, []);
		}

		return columnButtons?.length ? (
			columnButtons
		) : (
			<Grid item xs={12}>
				No columns found
			</Grid>
		);
	};

	// Used in hide/show MultiSelect component. Modifies selectedColumns and state based
	// on column toggling
	const onColumnToggle = async (e) => {
		// Table crashes if no columns selected. If user selects 0 columns, the first column will automatically be selected.
		setSelectedColumns(e.value.length ? e.value : [columns[0]]);
	};

	// Refresh user's LMAPI data, reload DataSpace
	const [lastRefreshDateTime, setLastRefreshDateTime] = useState('');
	const refreshDataSpace = async () => {
		// Reliant will not be refreshed here as it should be on a schedule or some other system.

		setLoadingOpen(true); // Initiates loading backdrop

		try {
			let dataSpaceData = (await updateDataSpaceData(props.dataSpaceId)).data;

			let rows = applyCustomDateFormats(dataSpaceData);
			const columns = generateAndAppendColumnToggleLabels(dataSpaceData.columns);
			setRows(rows);
			setColumns(columns);

			setLastRefreshDateTime(new Date().toLocaleString());

			trackEvent({
				userDetails: { userId: user._id, email: user.email },
				eventDetails: {
					types: ['KissMetrics', 'AppInsights', 'Segment', 'Encharge', 'GA4'],
					eventName: 'User refreshed DataSpace.',
				},
			});
			setAlertSeverity('success');
			setSnackMessage('Your DataSpace has been refreshed.');
			setSnackAutoHideMs(5000);
		} catch (e) {
			// If something goes wrong alert the user and have them retry
			trackEvent({
				userDetails: { userId: user._id, email: user.email },
				eventDetails: {
					types: ['Segment', 'Encharge', 'GA4'],
					eventName: 'User encountered issue when refreshing DataSpace.',
				},
			});
			setAlertSeverity('error');
			setSnackMessage(
				e.response?.data?.description?.includes('token_rejected')
					? 'Failed to fetch Yahoo data. Please reconnect your Yahoo account.'
					: 'Something went wrong. Please try again in a few seconds.',
			);
			setSnackAutoHideMs(5000);
		}

		// Close loading screen, show snackbar alert
		setLoadingOpen(false);
		setSnackOpen(true);
	};

	// Generate labels for column dropdown toggle
	const generateAndAppendColumnToggleLabels = (columns) => {
		return columns.map((c) => {
			return {
				...c,
				columnToggleLabel: c.header + ' - ' + c.dataSourceName,
			};
		});
	};

	// This function updates the column header in local and database states when the user renames
	// a column in the DataSpace. It is used as a callback in the ColumnHeader component.
	const renameColumnHeader = (field, newHeader) => {
		// Validation
		const errors = validateNewHeaderName(newHeader);
		if (errors) {
			displayAlert('error', errors);
			return;
		}

		// Rename column header locally
		let columnsCopy = [...columns];
		columnsCopy.find((x) => x.field === field).header = newHeader;
		columnsCopy = generateAndAppendColumnToggleLabels(columnsCopy);
		setColumns(columnsCopy);

		// Rename column header in DB
		const payload = {
			dataSpaceId: props.dataSpaceId,
			mode: 'headerNamesState',
			headerNamesState: {
				field: field,
				header: newHeader,
			},
		};
		updateDataSpaceStateNew(payload);
	};

	// Store dataSpaceId in context so it can be used later without prop drilling
	useEffect(() => {
		setDataSpaceId(props.dataSpaceId);
	}, []);

	// When renaming a column, validates the new header name. Returns an unordered list
	// of errors for display in alert. (eg <ul><li>{error message}</li></ul>)
	function validateNewHeaderName(newHeader) {
		const errors = [];
		let alertContent;
		// Check header name is unique
		if (columns.find((c) => c.header.toLowerCase() === newHeader.toLowerCase())) {
			errors.push(`There is already a column with the name "${newHeader}". Please choose a new column name.`);
			// displayAlert('error', `There is already a column with the name "${newHeader}". Please choose a new column name.`);
		}
		// Check new header is not empty string
		if (!/\S/.test(newHeader) || newHeader === '') {
			errors.push('Please enter a name that is not whitespace.');
		}
		// Create unordered list of errors
		if (errors.length > 0) {
			alertContent = (
				<ul>
					{errors.map((a) => (
						<li>{a}</li>
					))}
				</ul>
			);
		}
		return alertContent;
	}

	// Get lmapiName for DataSpace refresh, set last refresh date, build and set header, populate DataSpace
	useEffect(() => {
		const getDataSpaceData = async () => {
			try {
				// Get DataSpace
				const getDataSpacesRes = await getDataSpaces(props.dataSpaceId);
				const dataSpace = getDataSpacesRes.data.dataSpace;
				dataSpaceViewContext.setDataSpace({ ...dataSpace });
				myDataSpacesViewContext.setSelectedDataSpace({ ...dataSpace });

				setLastRefreshDateTime(new Date().toLocaleString());

				// Set LMAPI name (Used when DataSpace is refreshed)
				if (lmapiName) setLmapiName(lmapiName);

				// Build string to display in header below DataSpace Name
				let headerSubtitle;
				let emoji;
				let selectedSport = dataSpace.datasets[0].tags.sport;
				let selectedLeague = undefined;

				// Get emoji
				switch (selectedSport) {
					case 'NFL':
						emoji = '🏈';
						break;
					case 'NHL':
						emoji = '🏒';
						break;
					case 'MLB':
						emoji = '⚾';
						break;
					case 'NBA':
						emoji = '🏀';
						break;
					default:
						emoji = '😀';
				}

				// Build header subtitle
				const fantasyLeagueDataset = dataSpace.datasets.find((dataset) =>
					['Yahoo! Fantasy Sports', 'Fantrax'].includes(dataset.tags.api),
				);

				if (fantasyLeagueDataset) {
					headerSubtitle = `${selectedSport}, ${fantasyLeagueDataset.leagueName} ${emoji}`;
				} else headerSubtitle = `${selectedSport} ${emoji}`;

				// Set header
				setHeaderContent(null);

				// Get DataSpace Rows
				const getDataSpaceRowsRes = await getDataSpaceRows(dataSpace._id);

				setJoinedDataSpaceData({
					columns: dataSpace.columnMetadata,
					data: getDataSpaceRowsRes.data.rows,
					headerNamesState: dataSpace.headerNamesState,
				});
			} catch (e) {
				myDataSpacesViewContext.changeViewHandler('home');
				snackbarAlertContext.setSnackbarAlert({
					msg: 'Failed to load DataSpace. Please try again later. If the issue persists, please contact support.',
					severity: 'error',
					autoHide: 5000,
					isSnackbarOpen: true,
				});
			}
		};

		getDataSpaceData();

		// Clear header when exiting view
		return function clearHeader() {
			setHeaderContent(<p>SportWise</p>);
		};
	}, [myDataSpacesViewContext.selectedDataSpace?.isPublic]);

	function setJoinedDataSpaceData(dataSpaceData) {
		let { columns, headerNamesState } = dataSpaceData;
		const data = applyCustomDateFormats(dataSpaceData);

		// Populate table
		setRows(data);

		columns = columns.map((column) => {
			const headerName = headerNamesState?.find((headerName) => headerName.field === column.field);
			if (headerName) column.header = headerName.header;
			return column;
		});
		columns = generateAndAppendColumnToggleLabels(columns);
		setColumns(columns);
	}

	// This function is used to append new Calculated Column data (row data and column details) to the existing row and column state.
	const appendCalculatedColumn = async (columnDetails) => {
		// Process and append column
		let col = [];
		col.push(columnDetails.calcColDetails);
		col = generateAndAppendColumnToggleLabels(col);
		setColumns((prev) => {
			const toSet = prev.concat(col);
			return toSet;
		});

		// Append (join) calculated row data
		const { calculatedRows } = columnDetails;
		setRows((rows) => {
			for (const i in rows) {
				if (Object.keys(calculatedRows[i]).length > 0) {
					rows[i] = {
						...rows[i],
						...calculatedRows[i],
					};
				}
			}
			return rows;
		});

		const dataSpace = (await getDataSpaces(props.dataSpaceId)).data.dataSpace;

		// Update column metadata with new calculated column
		dataSpaceViewContext.setDataSpace((prevState) => ({
			...prevState,
			columnMetadata: dataSpace.columnMetadata,
		}));

		// Filter Initialization for new calculated column occurs in DataTable
	};

	// This function is used to replace the values of a custom column after it has been edited and recalculated
	const applyModifiedCustomColumn = async (columnDetails) => {
		// If column is dependency of other column, use retrieveJoinedDataSpaceData
		// so dependent columns are recalculated as well
		if (columnDetails.calcColDetails.isDependency) {
			try {
				const dataSpace = (await getDataSpaces(props.dataSpaceId)).data.dataSpace;

				const columns = dataSpace.columnMetadata.map((column) => ({
					_id: column._id,
					field: column.field,
					header: column.header,
					dataSourceName: column.dataSourceName,
					description: column.description,
				}));

				const rows = (await getDataSpaceRows(props.dataSpaceId)).data.rows;

				setJoinedDataSpaceData({
					columns,
					data: rows,
					headerNamesState: dataSpaceViewContext.dataSpace.headerNamesState,
				});
			} catch (e) {
				props.handleDataSpaceLoadingError();
			}
		}
		// If no dependent columns, replace existing column values with new values
		else {
			const { calculatedRows } = columnDetails;
			const { field } = columnDetails.calcColDetails;
			setRows((rows) => {
				for (const i in rows) {
					if (calculatedRows[i][field] || calculatedRows[i][field] === 0) rows[i][field] = calculatedRows[i][field];
					else delete rows[i][field];
				}
				return rows;
			});
		}
	};

	// Deletes a custom column from the frontend. Works by re-retrieving and reloading
	// DataSpace data. This method avoids clashing with filter system (rather than removing
	// the column data  explicitly from the rows and columns).
	const deleteCustomColumn = async () => {
		try {
			const dataSpace = (await getDataSpaces(props.dataSpaceId)).data.dataSpace;

			const columns = dataSpace.columnMetadata.map((column) => ({
				_id: column._id,
				field: column.field,
				header: column.header,
				dataSourceName: column.dataSourceName,
				description: column.description,
			}));

			const rows = (await getDataSpaceRows(props.dataSpaceId)).data.rows;

			setJoinedDataSpaceData({
				columns,
				data: rows,
				headerNamesState: dataSpaceViewContext.dataSpace.headerNamesState,
			});
		} catch (e) {
			// // Display error, send user back to My DataSpaces
			props.handleDataSpaceLoadingError();
		}
	};

	const openCalculatedColumnPanelOnClick = () => {
		setCalcColPanelOpen(true);
		trackEvent({
			userDetails: { userId: user._id, email: user.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'Encharge', 'GA4'],
				eventName: 'User opened Calculated Column Panel.',
			},
		});
	};

	return (
		<div className="dataspace-view">
			<LocalizationProvider dateAdapter={LuxonAdapter}>
				<DataTable
					columns={columns}
					rows={rows}
					dataSpaceId={props.dataSpaceId}
					renameHeader={renameColumnHeader}
					handleClickSeeDetails={handleClickSeeDetails}
					appendCalculatedColumn={appendCalculatedColumn}
					applyModifiedCustomColumn={applyModifiedCustomColumn}
					deleteCustomColumn={deleteCustomColumn}
					calcColPanelOpen={calcColPanelOpen}
					handleCloseCalcColPanel={handleCloseCalcColPanel}
					displayAlert={displayAlert}
					selectedColumns={selectedColumns}
					setSelectedColumns={setSelectedColumns}
				>
					<div className={classes.rightside_component_group}>
						<IconButton
							className={classes.button}
							color="secondary"
							onClick={refreshDataSpace}
							style={{ width: '2.5rem', height: '2.5rem' }}
						>
							<AutorenewOutlinedIcon />
						</IconButton>

						<IconButton
							className={classes.button}
							onClick={() => {
								setIsSelectColumnsDialogVisible(true);
							}}
							style={{ width: '2.5rem', height: '2.5rem' }}
						>
							<AddchartOutlinedIcon />
						</IconButton>

						<IconButton
							className={classes.button}
							onClick={openCalculatedColumnPanelOnClick}
							style={{ width: '2.5rem', height: '2.5rem' }}
						>
							<SvgIcon style={{ width: '1.3rem', height: '1.3rem' }}>
								<AddFormulaIcon />
							</SvgIcon>
						</IconButton>

						<CSVExporter
							dataSpaceId={props.dataSpaceId}
							dataSpaceName={myDataSpacesViewContext.myDataSpacesView.selectedDataSpace.name}
							displayAlert={displayAlert}
						/>

						<IconButton
							className={classes.button}
							style={{ width: '2.5rem', height: '2.5rem' }}
							onClick={handleClickSeeDetails}
						>
							<InfoOutlinedIcon />
						</IconButton>
					</div>
				</DataTable>
			</LocalizationProvider>

			<Dialog
				open={isSelectColumnsDialogVisible}
				onClose={() => {
					setIsSelectColumnsDialogVisible(false);
				}}
				fullWidth
			>
				<DialogTitle>
					Select Columns
					<IconButton
						aria-label="close"
						onClick={() => {
							setIsSelectColumnsDialogVisible(false);
						}}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<DisabledByDefaultIcon size="medium" />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<TextField
						onChange={selectColumnsDialogSearchChangeHandler}
						style={{ width: '100%', marginBottom: '1rem' }}
						value={selectColumnsDialogSearchInput}
						placeholder="Search columns"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						variant="standard"
					/>
					{dataSpaceViewContext.dataSpace.datasets &&
						dataSpaceViewContext.dataSpace.datasets.map((dataset) => (
							<div key={dataset._id} style={{ width: '100%', marginBottom: '2rem' }}>
								<div style={{ height: '5rem', width: '100%', marginBottom: '1rem', display: 'flex' }}>
									<img
										src={getLogo({ api: dataset.tags.api, sport: dataset.tags.sport })}
										alt="logo"
										style={{ height: '100%', borderRadius: '0.3rem', marginRight: '0.5rem' }}
									/>
									<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
										<h5 style={{ margin: '0 0 0.5rem 0', fontSize: '1.5rem' }}>
											{(dataset.displayName || dataset.name) +
												(dataset.tags.timeFrame ? ` (${dataset.tags.timeFrame})` : '') +
												` (${dataset._id.substr(-3)})`}
										</h5>
										<p style={{ height: '100%', margin: '0', overflow: 'auto' }}>
											Select columns below to view them in your DataSpace
										</p>
									</div>
								</div>
								<Grid container spacing={0.5}>
									{selectColumnsDatasetColumns(dataset)}
								</Grid>
							</div>
						))}

					{/* Calculated Columns*/}
					<div style={{ width: '100%', marginBottom: '2rem' }}>
						<div style={{ height: '5rem', width: '100%', marginBottom: '1rem', display: 'flex' }}>
							<img
								src={getLogo({ api: null, sport: null })}
								alt="logo"
								style={{ height: '100%', borderRadius: '0.3rem', marginRight: '0.5rem' }}
							/>
							<div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
								<h5 style={{ margin: '0 0 0.5rem 0', fontSize: '1.5rem' }}>Calculated Columns</h5>
								<p style={{ height: '100%', margin: '0', overflow: 'auto' }}>
									Select columns below to view them in your DataSpace
								</p>
							</div>
						</div>
						<Grid container spacing={0.5}>
							{selectColumnsDatasetColumns('Calculated')}
						</Grid>
					</div>
					{selectedColumns.length === columns.length ? (
						<Button variant="outlined" onClick={deselectAllColumnsClickHandler}>
							Deselect All Columns
						</Button>
					) : (
						<Button variant="outlined" onClick={selectAllColumnsClickHandler}>
							Select All Columns
						</Button>
					)}
				</DialogContent>
			</Dialog>

			<Backdrop className={classes.backdrop} open={loadingOpen}>
				<CircularProgress color="inherit" />
			</Backdrop>

			<ShareDataSpaceDialog />

			<Snackbar
				open={snackOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={onSnackClose}
				autoHideDuration={snackAutoHideMs}
			>
				<Alert severity={alertSeverity}>{snackMessage}</Alert>
			</Snackbar>

			<DetailsPane
				open={detailsOpen}
				onClose={onDetailsClose}
				dataSpaceId={props.dataSpaceId}
				lastRefreshDateTime={lastRefreshDateTime}
				setLoadingOpen={setLoadingOpen}
			/>
		</div>
	);
}
