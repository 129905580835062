import { useState, useEffect, useContext } from 'react';
import { Grid, Button, Typography, makeStyles, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import SingleSelect from '../../../ui_elements/SingleSelect';
import { getUsersLeagues, updateUserLeagues } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import AddFantasyLeagueDialog from './AddFantasyLeagueDialog';
import { useLocation, useHistory } from 'react-router-dom';
import { UserProfileContext } from '../../../../UserProfileContext';
import getLogo from '../../../../utils/getLogo';
import { trackEvent } from '../../../../utils/eventTracking';

const useStyles = makeStyles((theme) => ({
	singleSelect: {
		width: '100%',
		maxWidth: '25rem',
		marginBottom: '0.5rem',
	},
}));

// The SelectFantasyLeague is a dropdown input that allows the user to select a connected fantasy league as a datasource.
const SelectFantasyLeague = (props) => {
	let history = useHistory();
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const classes = useStyles();

	const userProfileContext = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	// This function displays an Alert to the user based on a given severity.
	// Severities include success, info, and error.
	const displayAlert = (alertType, customAlert) => {
		let alertConfigs = {
			refreshSuccess: {
				msg: 'Leagues refreshed successfully.',
				severity: 'success',
				autoHide: 5000,
			},
			refreshInProgress: {
				msg: 'Working on refreshing leagues...',
				severity: 'info',
				autoHide: null,
			},
			refreshError: {
				msg: 'Error refreshing leagues. Please try again later',
				severity: 'error',
				autoHide: 5000,
			},
		};

		if (customAlert) {
			snackbarAlertContext.setSnackbarAlert({ ...customAlert, isSnackbarOpen: true });
		} else {
			snackbarAlertContext.setSnackbarAlert({ ...alertConfigs[alertType], isSnackbarOpen: true });
		}
	};

	// Calls the Azure function GetUsersLeagues and updates the
	const updateLeagues = async () => {
		let res = await getUsersLeagues();
		createEditDataSpaceContext.setConnectedFantasyLeagues(res.leagues);
	};

	// Handles when a user selects a fantasy league.
	// Resets any selected sports league and sets the fantasy league property of the CreateEditDataSpaceContext.
	const selectedLeagueChangeHandler = (e) => {
		const { name, _id } = createEditDataSpaceContext.newDataSpace;
		createEditDataSpaceContext.resetNewDataSpace();
		createEditDataSpaceContext.setNewDataSpace((prevState) => ({
			...prevState,
			_id,
			name,
			fantasyLeague: e.target.value,
		}));
	};

	const handleClickOpenDialog = () => {
		setIsDialogOpen(true);
	};

	// Updates leagues from fantasy APIs
	const handleClickRefreshLeagues = async () => {
		try {
			createEditDataSpaceContext.setConnectedFantasyLeagues(undefined);

			await updateUserLeagues({ userId: userProfileContext._id });

			// Fetch and update leagues.
			updateLeagues();

			const dataSpaceName = createEditDataSpaceContext.newDataSpace.name;
			createEditDataSpaceContext.resetNewDataSpace({
				_id: createEditDataSpaceContext.newDataSpace._id,
				name: dataSpaceName,
			});
			displayAlert('refreshSuccess');
		} catch (e) {
			if (e.response?.data?.config?.url?.endsWith('get_token')) {
				return displayAlert(null, {
					msg: 'Failed to refresh leagues. Please reconnect your Yahoo account.',
					severity: 'error',
					autoHide: 5000,
				});
			}
			displayAlert('refreshError');
			return; // Do not update leagues
		}
	};

	// Populate User League dropdown.
	useEffect(() => {
		if (params.get('route') === 'dataSpaceForm') {
			try {
				trackEvent({
					userDetails: { userId: userProfileContext._id, email: userProfileContext.email },
					eventDetails: {
						types: ['KissMetrics', 'Segment', 'Encharge', 'AppInsights'],
						eventName: 'WebApp_User Connects Yahoo! Fantasy Sports Account',
					},
				});
				handleClickRefreshLeagues();
				history.replace('/app');
			} catch (e) {
				displayAlert(null, { msg: 'Failed to fetch fantasy league(s).', severity: 'error', autoHide: 5000 });
			}
		} else {
			updateLeagues();
		}
	}, []);

	return (
		<Grid item container xs={12} md={6} style={{ marginBottom: '1rem', ...props.style }}>
			<AddFantasyLeagueDialog
				isDialogOpen={isDialogOpen}
				setIsDialogOpen={setIsDialogOpen}
				updateLeagues={updateLeagues}
				callBackRoute={'dataSpaceForm'}
			/>

			<Grid item xs={12}>
				<Typography variant="h6" align="left" marginBottom="0.5rem">
					Select Your Fantasy League
				</Typography>
			</Grid>

			<Grid item container xs={12}>
				<FormControl fullWidth>
					<Select
						displayEmpty
						className={classes.singleSelect}
						onChange={selectedLeagueChangeHandler}
						value={createEditDataSpaceContext?.newDataSpace?.fantasyLeague || null}
						renderValue={(fantasyLeague) => {
							return !fantasyLeague ? (
								<span style={{ color: 'grey' }}>No league selected</span>
							) : (
								<div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
									<img
										src={getLogo({ api: fantasyLeague.api })}
										style={{ width: '2rem', borderRadius: '0.2rem', marginRight: '0.5rem' }}
									/>
									<span
										style={{
											width: '100%',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}
									>
										{fantasyLeague.displayName}
									</span>
								</div>
							);
						}}
					>
						{createEditDataSpaceContext.connectedFantasyLeagues ? (
							createEditDataSpaceContext.connectedFantasyLeagues.length ? (
								createEditDataSpaceContext.connectedFantasyLeagues.map((connectedFantasyLeague) => (
									<MenuItem value={connectedFantasyLeague}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<img
												src={getLogo({ api: connectedFantasyLeague.api })}
												style={{ width: '2rem', borderRadius: '0.2rem', marginRight: '0.5rem' }}
											/>
											{connectedFantasyLeague.displayName}
										</div>
									</MenuItem>
								))
							) : (
								<MenuItem disabled={true}>No leagues connected</MenuItem>
							)
						) : (
							<MenuItem disabled={true}>Loading...</MenuItem>
						)}
					</Select>
				</FormControl>
			</Grid>

			<Grid item container xs={12} md={12}>
				{createEditDataSpaceContext.createEditDataSpaceMode !== 'edit' ? (
					<Button size="small" variant="contained" color="secondary" margin="normal" onClick={handleClickOpenDialog}>
						Connect New Leagues
					</Button>
				) : null}
				<Button
					margin="normal"
					onClick={async () => {
						// Open info alert to tell user refresh is in progress
						displayAlert('refreshInProgress');
						await handleClickRefreshLeagues();
					}}
				>
					Refresh Leagues
				</Button>
			</Grid>
		</Grid>
	);
};

export default SelectFantasyLeague;
