import { useContext } from 'react';
import { ListItemIcon, MenuItem, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';

const DeleteDatasetButton = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);
	const userProfile = useContext(UserProfileContext)[0];

	const deleteDatasetButtonClickHandler = () => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Clicks delete custom dataset button',
			},
		});

		customDatasetsContext.setView('delete');
	};

	return (
		<MenuItem sx={{ color: (theme) => `${theme.palette.danger.main}` }} onClick={deleteDatasetButtonClickHandler}>
			<ListItemIcon sx={{ color: 'inherit' }}>
				<SvgIcon fontSize="1rem" component={DeleteIcon} />
			</ListItemIcon>

			<Typography variant="body1" color="inherit">
				Delete
			</Typography>
		</MenuItem>
	);
};

export default DeleteDatasetButton;
