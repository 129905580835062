import { useEffect, useContext } from 'react';
import { trackEvent } from '../../utils/eventTracking';
import DataSpacesList from './DataSpacesList';
import SearchInput from './SearchInput';
import FilterSortInputs from './FilterSortInputs';
import { UserProfileContext } from '../../UserProfileContext';
import DataSpaceInfoDialog from './DataSpaceInfoDialog';
import CrowdPng from '../../assets/img/Crowd.png';

// Community DataSpaces page.
const CommunityDataSpaces = () => {
	const userProfile = useContext(UserProfileContext)[0];

	useEffect(() => {
		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
				eventName: 'User visted Community DataSpaces page.',
			},
		});
	}, []);

	return (
		<div
			style={{
				width: '90%',
				maxWidth: '100rem',
				margin: '0 auto',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				paddingRight: '0.5rem',
			}}
		>
			<h1 style={{ fontSize: '3rem', fontWeight: 'lighter', marginBottom: '0', textAlign: 'center' }}>
				{window.kmval?.communityDataSpacesUi === 'communityCatalogue' ? 'Community Catalog' : 'Explore'}
			</h1>
			<p style={{ margin: '1rem 0 2rem 0', fontWeight: 'bold', textAlign: 'center' }}>
				DataSpaces by sports fans, for sports fans
			</p>
			{window.kmval?.communityDataSpacesUi === 'communityCatalogue' && (
				<img src={CrowdPng} alt="Crowd" style={{ width: '100%', maxWidth: '40rem', opacity: '0.65' }} />
			)}
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						position: 'sticky',
						top: '0',
						paddingTop: '0.5rem',
						paddingBottom: '1.2rem',
						backgroundColor: 'white',
						overflowX: 'hidden',
					}}
				>
					<FilterSortInputs />
					<SearchInput />
				</div>
				<DataSpacesList />
				<DataSpaceInfoDialog />
			</div>
		</div>
	);
};

export default CommunityDataSpaces;
