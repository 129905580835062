import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import { useContext, useEffect, useState } from 'react';
import { getDataSpaces, deleteDataset } from '../../api_helper/api';
import { UserProfileContext } from '../../UserProfileContext';
import { trackEvent } from '../../utils/eventTracking';

const DeleteDatasetDialog = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);
	const userProfile = useContext(UserProfileContext)[0];

	const [dataSpaces, setDataSpaces] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const closeDialogHandler = () => {
		customDatasetsContext.setView(null);
		customDatasetsContext.setCustomDataset(null);
	};

	const deleteDatasetConfirmationButtonClickHandler = async () => {
		setIsLoading(true);

		await deleteDataset(customDatasetsContext.customDataset._id);

		// Remove loading animation
		setIsLoading(false);

		// Close the dialog
		closeDialogHandler();

		// Refresh the list
		customDatasetsContext.setCustomDatasets(undefined);

		trackEvent({
			userDetails: { userId: userProfile._id, email: userProfile.email },
			eventDetails: {
				types: ['KissMetrics', 'AppInsights', 'Segment', 'GA4'],
				eventName: 'WebApp_User Deletes custom dataset',
			},
		});
	};

	useEffect(() => {
		const getDataSpacesWithDataset = async () => {
			const dataSpaces = await getDataSpaces(null, null, null, customDatasetsContext.customDataset?._id);
			setDataSpaces(dataSpaces);
		};

		if (customDatasetsContext.view === 'delete') {
			getDataSpacesWithDataset();
		}
	}, [customDatasetsContext.customDataset, customDatasetsContext.view]);

	return (
		Boolean(customDatasetsContext.view === 'delete') && (
			<Dialog open={true} onClose={closeDialogHandler}>
				<DialogTitle>{'Delete Dataset'}</DialogTitle>
				{dataSpaces ? (
					<DialogContent>
						{dataSpaces.length === 0 ? (
							<DialogContentText>Are you sure you want to delete this dataset?</DialogContentText>
						) : (
							<>
								<DialogContentText
									sx={{ color: (theme) => `${theme.palette.danger.main}`, margin: '0.75rem 0 0.5rem 0' }}
								>
									Dataset cannot be deleted as it is used by the following DataSpaces:
								</DialogContentText>

								<ul style={{ paddingLeft: '1.5rem', margin: '0' }}>
									{/* Display names of DataSpace created by the user that use the custom dataset */}
									{dataSpaces
										.filter((dataSpace) => dataSpace.createdBy === userProfile._id)
										.map((dataSpace) => (
											<li>
												<DialogContentText key={dataSpace._id} variant="subtitle1" sx={{ textAlign: 'start' }}>
													{dataSpace.name}
												</DialogContentText>
											</li>
										))}

									{/* Tell user if their dataset it being used by DataSpaces in other accounts */}
									{dataSpaces.some((dataSpace) => dataSpace.createdBy !== userProfile._id) && (
										<li>
											<DialogContentText variant="subtitle1" sx={{ textAlign: 'start' }}>
												<em>DataSpaces created by other users.</em>
											</DialogContentText>
										</li>
									)}
								</ul>
							</>
						)}
					</DialogContent>
				) : (
					<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
						<CircularProgress />
					</Box>
				)}

				{/* Action buttons */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'end',
						padding: '1.25rem 1.5rem',
						borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
					}}
				>
					<Button
						variant="outlined"
						color="neutral"
						onClick={closeDialogHandler}
						sx={{ marginRight: '0.75rem' }}
						disabled={isLoading}
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="danger"
						onClick={deleteDatasetConfirmationButtonClickHandler}
						disabled={isLoading || dataSpaces?.length > 0}
					>
						Delete Dataset
					</Button>
				</Box>
			</Dialog>
		)
	);
};

export default DeleteDatasetDialog;
