/*
This file is for viewing DataSpaces and views coming from shared links.
Checks if the DataSpace/view is shared and if not, shows an access denied page.
*/

import { useState, useEffect, useContext } from 'react';
import DataSpaceViewNew from './dataspace_view_new/DataSpaceView';
import { DataSpaceViewProvider } from '../my_dataspaces/context/DataSpaceViewContext';
import SportWiseLogo from '../../assets/img/SportWiseLogoSmall.png';
import { Button, IconButton, Backdrop, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { getDataSpaces } from '../../api_helper/api';
import { useLocation, useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ErrorPNG from '../../assets/img/Error.png';
import SportWiseLogoPNG from '../../assets/img/SportWiseLogoLarge.png';
import { trackEvent } from '../../utils/eventTracking';
import { UserProfileContext } from '../../UserProfileContext';

const SharedDataSpaceView = () => {
	const routeParams = useParams();
	let history = useHistory();

	const { search } = useLocation();
	const params = new URLSearchParams(search);

	const userProfile = useContext(UserProfileContext)[0];

	const [isShared, setIsShared] = useState();
	const [isPopupVisible, setIsPopupVisible] = useState(true);

	// Get the DataSpace ID from the URL
	const dataSpaceId = (() => {
		const lastIndex = routeParams.dataSpaceNameAndId.lastIndexOf('-');
		if (lastIndex !== -1) {
			// If a hyphen is found, return the substring after the last hyphen
			return routeParams.dataSpaceNameAndId.substring(lastIndex + 1);
		} else {
			// If no hyphen is found, return the entire string
			return routeParams.dataSpaceNameAndId;
		}
	})();

	useEffect(() => {
		// This function checks if the DataSpace name in the URL is correct and corrects it if it isn't.
		const correctUrl = (dataSpace) => {
			// Format DataSpace name to include in URL.
			const formmattedName = dataSpace.name
				.slice(0, 100)
				.replace(/ /g, '-')
				.replace(/[^\w\s-]/gi, '');

			// Extract DataSpace name from the URL
			const lastIndex = routeParams.dataSpaceNameAndId.lastIndexOf('-');
			const name = lastIndex !== -1 ? routeParams.dataSpaceNameAndId.substring(0, lastIndex) : '';

			if (name !== dataSpace.name) {
				let link =
					'/app/dataSpaces/' + formmattedName + '-' + dataSpace._id + (params.get('v') ? '?v=' + params.get('v') : '');

				// Redirect to the correct URL
				history.replace(link);
			}
		};

		const fetchDataSpaceSharedState = async () => {
			// Get DataSpace
			const getDataSpacesRes = await getDataSpaces(dataSpaceId);
			const dataSpace = getDataSpacesRes.data.dataSpace;
			correctUrl(getDataSpacesRes.data.dataSpace);

			// If view is specified, check if viewing is allowed using the view's isShared property.
			if (params.get('v')) {
				const view = dataSpace.views.find((view) => view.id === params.get('v'));
				if (view) {
					setIsShared(view.isShared ? true : false);
				} else {
					setIsShared(false);
				}
				return;
			}

			// Check if viewing is allowed using the DataSpace's isShared property.
			setIsShared(Boolean(dataSpace.isShared));
		};
		fetchDataSpaceSharedState();
	}, []);

	return isShared ? (
		<DataSpaceViewProvider>
			<div
				style={{
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						width: 'clamp(22rem, 90%, 35rem)',
						position: 'absolute',
						top: '1rem',
						left: '50%',
						transform: 'translateX(-50%)',
						padding: '0.5rem 1rem',
						display: isPopupVisible ? 'flex' : 'none',
						justifyContent: 'center',
						alignItems: 'center',
						flexWrap: 'wrap',
						zIndex: '1000',
						backgroundColor: '#FFFFFF',
						borderRadius: '0.5rem',
						boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.25)',
					}}
				>
					<img src={SportWiseLogo} style={{ height: '4rem', marginRight: '0.5rem' }} />
					<span style={{ minWidth: '12rem', maxWidth: '20rem' }}>
						<b>Welcome to SportWise!</b> Create an account to create and share your own DataSpaces.
					</span>
					<Button
						variant="contained"
						color="success"
						style={{ minWidth: 'fit-content', margin: '1rem', color: '#FFFFFF' }}
						onClick={() => {
							trackEvent({
								userDetails: { userId: userProfile?._id, email: userProfile?.email },
								eventDetails: {
									types: ['KissMetrics', 'Segment', 'AppInsights', 'GA4'],
									eventName: 'User clicked Sign Up button from shared DataSpace.',
								},
							});

							// Kissmetrics A/B test to open signup link in new tab or same tab
							window.kmval?.openSignupLink === 'newTab'
								? window.open('/register', '_blank')
								: history.push('/register');
						}}
					>
						Sign Up
					</Button>
					<IconButton
						style={{
							width: '1rem',
							height: '1rem',
							position: 'absolute',
							top: '0.5rem',
							right: '0.5rem',
						}}
						onClick={() => {
							setIsPopupVisible(false);
						}}
					>
						<CloseIcon size="small" />
					</IconButton>
				</div>
				<DataSpaceViewNew dataSpaceId={dataSpaceId} handleDataSpaceLoadingError={() => {}} />
			</div>
		</DataSpaceViewProvider>
	) : isShared === undefined ? (
		<Backdrop open={true}>
			<CircularProgress style={{ color: '#fff' }} />
		</Backdrop>
	) : (
		<div
			style={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				color: '#373A3C',
			}}
		>
			<img src={SportWiseLogoPNG} style={{ position: 'absolute', top: '1rem', left: '1rem', width: '10rem' }} />
			<img src={ErrorPNG} style={{ width: '80%', maxWidth: '25rem', marginBottom: '1rem' }} />
			<h1 style={{ fontSize: '3rem', margin: '0' }}>Uh Oh!</h1>
			<p style={{ paddingBottom: '2rem' }}>
				The page your looking for does not exist or you do not have access to view it.
			</p>
			<Button
				variant="contained"
				onClick={() => {
					history.push('/app');
				}}
			>
				Back to SportWise
			</Button>
		</div>
	);
};

export default SharedDataSpaceView;
